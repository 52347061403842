import React from 'react';

import LvlupLogo from '@/src/images/lvl-up-logo.png';
import { LANG_US_EN } from '@/src/common/consts/lang';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import Layout from '../../components/layout';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import ImageText from '../../components/sections/imageText/imageText';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import CtaST from '../../components/staticSections/CtaST/CtaST';

const LANG = LANG_US_EN;

const IndexPage = () => (
  <Layout
    lang="us"
    topHeader
    headerWitdh="header"
    headerStyle="flex--space-between"
    text="Partnered with LVL Up"
    showFooter
    headerImage={
      <img className="logo--md margin-left--xs flex" alt="LVL Up" src={LvlupLogo} />
    }
  >
    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
    <Seo title="LVL Up" language="en" />
    <HeroCTA
      dataTheme="ice-blue"
      idCta="herobutton"
      imagedata4
      dataSection="hero_CTA"
      alt="LVL Up"
      title="Treat joint and back pain from home"
      titleClassName=""
      text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
      textClassName=""
      tagHeadline="Get a text to download the app"
      smsTag
      dataToken="eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzE1MjIxMDcsImp0aSI6ImE5MTAzMzk5LWJhZWItNDljZC04NGQzLTgyOWVlNTYxNmU4ZCIsImFmZmlsaWF0ZV9uYW1lIjoiTFZMIFVQIFBUIn0.2tvdaRyays-df85FkncWupYk2d-PY5kWqcj-8fcz1Tw"
    />
    <ImageText
      imgSize="col--lg-4"
      Filename="lvl-up-logo.png"
      alt="LVL Up"
      Position=""
      title="Partnered with LVL Up"
      text="LVL Up has partnered with Joint Academy to provide online physical therapy. The program might be covered by your health plan at no cost to you. Download the Joint Academy app to get started."
      download
      downloaddataGoal="Onboarding Viewed"
      downloadhref="#herotext"
      downloadclassname="btn btn--primary btn--md"
      downloadtext="get started"
    />
    <GetStartedST
      lang={LANG}
      renderDownloadButton
      ctaButtonText="Get Started"
      ctaDataGoal="Onboarding Viewed"
      ctaHref="#herotext"
    />
    <BgCardsTextST dataTheme="light" lang={LANG} />
    <SocialProofFeedST lang={LANG} />
    <TestimonialGridST lang="us_en_v2" />
    <LicensedPtSliderST
      lang={LANG}
      downloaddataGoal="Onboarding Viewed"
      downloadhref="#herotext"
      downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
      downloadtext="Get started today"
    />
    <OutcomeST lang={LANG} />

    <FaqST lang={LANG} />
    <CtaST
      lang={LANG}
      ctaDataGoal="Calendly Viewed"
      ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
      ctaButtonText="Schedule Call"
    />
  </Layout>
);

export default IndexPage;
